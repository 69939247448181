import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
// import { Location } from '@reach/router';
import Img from "gatsby-image";

import Layout from "../components/layout";
import Title from "../components/title";
import Inview from "../components/inview";

export default function Logisticsequipment({ data }) {
  const pageData = {
    titleClass: "logistics",
    title: "ECソリューション事業部",
    discription: "",
    image: 5,
  };
  const seodata = {
    title: "EC Solution Division アヴィエラン株式会社　ECソリューション事業部",
    seo: {
      description: "事業計画の立案、マーケティング支援、サーバー構築、サイトの構築・解析・改善・更新やコンテンツページ作成、メルマガ配信などの運営まで、ECサイトの発展に向けて、お客様の事業活動をサポートします",
      image: {
        url: data.datoCmsImage.topimage[5].url,
      },
    },
    slug: "ec-solution-division",
  };
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
      <div className="pankz">
        <Link to="/">トップ</Link>
        <p>ECソリューション事業部</p>
      </div>
      <div className="l_page_contents">
        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[5].news} alt="アヴィエラン株式会社　ECソリューション事業部" />
            <Inview c_name="title" tag="p">
              新しい世界の創造
              <span>Creation of a new world</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              事業計画の立案、マーケティング支援、サーバー構築、サイトの構築・解析・改善・更新やコンテンツページ作成、メルマガ配信などの運営まで、ECサイトの発展に向けて、お客様の事業活動をサポートします
            </Inview>
          </div>
        </div>
        <div className="box1 ">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[20].news} alt="アヴィエラン株式会社　ECソリューション事業部" />
            <Inview c_name="title" tag="p">
              ECサイト運用支援
              <span>EC site support</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              自社EC運営に関して一部業務をアウトソーシングしたい企業様向けのサービス。 メルマガ運用、特集・キャンペーンなどのコンテンツページ作成、新商品の商品登録など、日々のサイト運営に必要な作業の一部をお手伝いさせていただきます。
            </Inview>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[17].news} alt="アヴィエラン株式会社　ECソリューション事業部" />
            <Inview c_name="title" tag="p">
              サイト改善
              <span>Site improvement</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              Googleアナリティクス等、各種解析ツールから得た情報をもとに、ECサイトの課題を発見・分析して改善致します。
            </Inview>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[19].news} alt="アヴィエラン株式会社　ECソリューション事業部" />
            <Inview c_name="title" tag="p">
              自社ECサイト立ち上げ支援
              <span>Printing service</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              ECモール（Amazon・楽天などのECサイトプラットフォーム）展開だけでなく自社ECを始めたい企業様向けのサービス。 自社ECを始めるにあたっての事業計画、要件定義、システム構築、サイトデザイン、運用更新などを支援させていただきます。
            </Inview>
          </div>
        </div>
        <div className="case_study">
          <div className="title">
            導入事例<span>case study</span>
          </div>
          <a href="https://amerivintage.co.jp/" target="_blank" rel="noreferrer">
            <Img fluid={data.datoCmsImage.topimage[22].news} alt="ameri vintage" />
          </a>
          <a href="https://ommo-ommo.com/" target="_blank" rel="noreferrer">
            <Img fluid={data.datoCmsImage.topimage[35].news} alt="ommo" />
          </a>
          <a href="https://store.osmosis.co.jp/" target="_blank" rel="noreferrer">
            <Img fluid={data.datoCmsImage.topimage[25].news} alt="OSMOSIS" />
          </a>
          <a href="https://store.noid.jp/" target="_blank" rel="noreferrer">
            <Img fluid={data.datoCmsImage.topimage[24].news} alt="NO ID." />
          </a>
          <a href="https://www.necosekai.shop/" target="_blank" rel="noreferrer">
            <Img fluid={data.datoCmsImage.topimage[23].news} alt="NECOSEKAI" />
          </a>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    datoCmsImage {
      topimage {
        url
        news: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "600", h: "600" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
